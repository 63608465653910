@import '../../scss/main.scss';

.chat-button {
  font-family: $font-chat;
  border: none;
  background-color: #f0f1f2;
  min-width: 120px;
  color: $color-primary;
  margin: 0.5em;
  padding: 0.5em;
  font-size: 1em;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    filter: brightness(0.95);
  }
  &.active {
    color: white;
    background-color: $color-primary;
    pointer-events: none;
  }
  &.disabled {
    color: lightgrey;
    pointer-events: none;
  }
}
