@import './scss/main';

body,
html {
  margin: 0;
  padding: 0;
  font-family: $font-chat;
}

#byk-cs {
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  position: relative;
}

.p-component {
  font-family: $font-chat;
}

.chat-list-item {
  display: flex;
  border: 1px;
  justify-content: space-between;

  div {
    width: 100%;
  }

  &-data {
    width: 70%;
    justify-content: space-evenly !important;
  }

  &-info {
    background: lightskyblue !important;
  }

  div.last-message-div {
    overflow: hidden;
  }

  p.last-message-paragraf {
    word-break: break-word;
  }
}

.selected-chat {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 46px - 52px - 1px);

  &-content {
    border-radius: 1px;
    overflow-y: scroll;
    margin-top: 10px;
    padding: 2px 10px 10px;
    transition: 0.2s;
    box-sizing: inherit;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &-message {
      &-user {
        display: flex;
        justify-content: flex-end;
      }

      &-event {
        font-style: italic;
        text-decoration: underline;
      }
    }
  }

  &-keypad {
    display: flex;
    height: 3rem;

    &-textarea {
      width: 90%;
      resize: none;
    }

    &-button {
      width: 10% !important;
    }
  }

  a {
    background: url(./static/link-external-blue.svg) no-repeat right center;
    padding-right: 1.2rem;
  }
}

.selected-chat {
  justify-content: space-between;

  &-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: auto;
  }
}
